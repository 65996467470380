import { useRouter } from 'next/router';
import { useEffect, useState } from 'react';

// The solution was taken from this link
// https://stackoverflow.com/questions/73343986/next-js-abort-fetching-component-for-route-login
const useSafeReplace = () => {
	const [onChanging, setOnChanging] = useState(false);
	const handleRouteChange = () => {
		setOnChanging(false);
	};
	const router = useRouter();
	// safePush is used to avoid route pushing errors when users click multiple times or
	// when the network is slow:  "Error: Abort fetching component for route"
	const safeReplace = (path: string) => {
		if (onChanging) {
			return;
		}
		setOnChanging(true);
		const redirectUrl = localStorage.getItem('redirectUrl');
		if (redirectUrl) {
			localStorage.removeItem('redirectUrl');
		}
		//TODO: Need to replace this with safeReplace(path) everywhere in the code;
		router.replace(path);
	};

	useEffect(() => {
		router.events.on('routeChangeComplete', handleRouteChange);

		return () => {
			router.events.off('routeChangeComplete', handleRouteChange);
		};
	}, [router, setOnChanging]);
	return { safeReplace };
};

export default useSafeReplace;
