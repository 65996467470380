import React, { useEffect } from 'react';
import { useRouter } from 'next/router';
import { useSelector } from 'react-redux';
import { RootState } from '@/store/slices';
import useSafeReplace from './useSafeReplace';

const withAuth = (Component: React.ComponentType<Record<string, unknown>>) => {
	const WithAuth = function (props: Record<string, unknown>) {
		const router = useRouter();
		const { safeReplace } = useSafeReplace();
		const { token, logout } = useSelector((state: RootState) => state.auth);

		useEffect(() => {
			if (!token && !logout) {
				localStorage.setItem('redirectUrl', router.asPath);
			}
			if (!token) {
				//This is the param that is being returned from the auth0 callback.
				//We are using this to show the user the success message after they have changed their password.
				if (router.asPath.includes('success=true')) {
					safeReplace(`/login?${router.asPath.slice(2)}`);
				} else {
					safeReplace('/login');
				}
			}
		}, [token, safeReplace, router, logout]);

		if (!token) {
			return <></>;
		}

		return <Component {...props} />;
	};

	WithAuth.displayName = Component.displayName ?? Component.name;
	return WithAuth;
};

export default withAuth;
